<template>
	<div class="login">
		<div class="box-login">
			<div class="logo"></div>
			<alert-message/>
			<div v-if="aviso_teste">
			<div class="message_alert">
				<strong>Atenção !</strong> <br /><br />
				Este ambiente é apenas de testes, para que os advogados possam se familiarizar com a ferramenta
				de inscrição. <br /><b>Sua inscrição aqui não será válida.</b>
			</div>
			</div>
			<div v-if="fechadas">
				<br />
				<strong>Olá, Advogado!</strong> <br /><br />
				As inscrições de Dativos na OAB-ES serão iniciadas em breve.
			</div>

			<div v-if="encerradas">
				<br />
				<strong>Olá, Advogado!</strong> <br /><br />
				As inscrições de Dativos foram encerradas. Entre em contato com a OAB-ES para saber mais informações.
			</div>

			<div v-if="em_curso">
				<!-- INFORMAÇÕES - INÍCIO -->
				<div :class="{info:true, active: etapa_numero_oab}">
					<strong>Olá, Advogado!</strong> <br /><br />
					Para prosseguir com sua inscrição como Dativo na OAB-ES, conferir seus dados pessoais, ou ter acesso aos dados de inscrição, digite seu número da OAB no campo abaixo:
				</div>
				<div :class="{info:true, active: etapa_senha}">
					<strong>Agora entre com sua senha:</strong> <br /><br />
					Sua senha é formada pelos 4 primeiros dígitos do seu CPF seguido do dia e ano do seu nascimento(dois últimos dígitos).
					<br />
					<br />
					<div class="info-senha" >
						<div style="display: flex; flex-direction: column;  justify-content: center; ">
							<div class="digits-list" style="display: flex;justify-content: space-between;align-items: center;width: 100%;">
								<span style="font-weight: 700;">CPF de exemplo: </span>
							<ul class="digits-list" style="padding-left: unset;list-style-type: none;display: flex;gap: 2.5px;text-align: center;font-family: arial;align-items: end;font-size: 14px;font-weight: 600;justify-content: flex-start;">
								<li class="digit digit-active">1</li>
								<li class="digit digit-active">4</li>
								<li class="digit digit-active">1</li>
								<li>.</li>
								<li class="digit digit-active">4</li>
								<li class="digit">8</li>
								<li class="digit">1</li>
								<li>.</li>
								
								<li class="digit">8</li>
								<li class="digit">0</li>
								<li class="digit">0</li>
								<li>-</li>
								<li class="digit">9</li>
								<li class="digit">2</li>
							</ul></div>
							<div class="digits-list" style="display: flex;justify-content: space-between;align-items: center;width: 100%;">
								<span style="font-weight: 700;">Data de Nascimento de exemplo: </span>
							<ul class="digits-list" style="padding-left: unset;list-style-type: none;display: flex;gap: 2.5px;text-align: center;font-family: arial;align-items: end;font-size: 14px;font-weight: 600;">
								<li class="digit digit-blue">0</li>
								<li class="digit digit-blue">1</li>
								
								<li>/</li>
								<li class="digit">0</li>
								<li class="digit">9</li>
								
								<li>/</li>
								
								<li class="digit">1</li>
								<li class="digit">9</li>
								<li class="digit digit-blue-trevoso">7</li>
								<li class="digit digit-blue-trevoso">6</li>

							</ul></div><div class="digits-list" style="display: flex;justify-content: space-between;align-items: center;width: 100%;">
								<span style="font-weight: 700;">Senha formada com os dados de exemplo: </span>
							<ul class="digits-list" style="padding-left: unset;list-style-type: none;display: flex;gap: 2.5px;text-align: center;font-family: arial;align-items: end;font-size: 14px;font-weight: 600;">
								<li class="digit digit-active">1</li>
								<li class="digit digit-active">4</li>
								<li class="digit digit-active">1</li>
								<li class="digit digit-active">4</li>
								<li class="digit digit-blue">0</li>
								<li class="digit digit-blue">1</li>
								<li class="digit digit-blue-trevoso">7</li>
								<li class="digit digit-blue-trevoso">6</li>
							</ul></div>
						</div>
					</div>
				</div>
				<!-- INFORMAÇÕES - FIM -->

				<!-- CAMPOS - INÍCIO -->
				<div v-if="etapa_numero_oab" class="field">
					<input v-on:keyup.enter="submit()" type="text" v-model="numero_oab"
						placeholder="Seu número da OAB. Ex.:12345">
				</div>
				<div v-if="etapa_senha" class="field">
				<span style="font-size:16.5px; font-weight:700;">Com base no exemplo acima, utilize seu CPF e  Data de Nascimento para formar sua senha</span>
					<input type="number" class="senha" v-on:keyup.enter="submit()" v-model="senha_advogado"
						placeholder="Digite sua senha">
				</div>

				<!-- CAMPOS - FIM -->
				<div class="button">
					<button v-on:click="submit()">Próximo</button>
				</div>
				
			</div>
		</div>
	</div>
</template>

<script>
import AlertMessage from './components/AlertMessage.vue';
	export default {
		name: "login",
		components: {AlertMessage},
		data() {
			return {
				em_curso: true,
				encerradas: false,
				fechadas: false,
				aviso_teste: false,
				//etapas
				etapa_numero_oab: true,
				etapa_senha: false,
				numero_oab: '',
				senha_advogado: '',
				consulta_inscricoes_abertas: this.$store.state.url_api + '/inscricoesAbertas.php',
				valida_oab_url: this.$store.state.url_api + '/validaNumeroOAB.php',
				login_url: this.$store.state.url_api + '/login.php',
				valida_token_url: this.$store.state.url_api + '/verificaTokenValido.php'
			};
		},
		// debug para verificar, no console, se a API está retornando os dados corretamente quanto a ambient, se está em produção ou teste
		// mounted() {
		// 	const Http = new XMLHttpRequest();
		// 	const url = this.consulta_inscricoes_abertas;
		// 	Http.open("GET", url);
		// 	Http.send();
		// 	Http.onreadystatechange = (e) => {
		// 		if (Http.readyState == 4 && Http.status == 200) {
		// 			console.log(Http.responseText);
		// 		}
		// 	}
		// },
		methods: {
			submit: async function () {
				this.$emit('loader', { active:true });
				if (this.etapa_numero_oab) {

					const Http = new XMLHttpRequest();
					const url = this.valida_oab_url;

					var data = new FormData();
					data.append('oab', this.numero_oab);
					Http.open("POST", url);
					Http.send(data);
					Http.onreadystatechange = (e) => {
						if (Http.readyState == 4 && Http.status == 200) {
							this.$emit('loader', { active:false });
							var res = Http.responseText;
							res = JSON.parse(res);
							if (res.numeroValido) {
								this.etapa_numero_oab = false;
								this.etapa_senha = true;
								this.$emit('loader', { active:false });
								this.$emit('alert_called', { message: 'Número da OAB reconhecido!', type: 'success' });
							} else {
								this.$emit('loader', { active:false });
								this.$emit('alert_called', { message: 'Número da OAB inválido!', type: 'danger' });
							}
						}
					}
				} else if (this.etapa_senha) {

					const Http = new XMLHttpRequest();
					const url = this.login_url;
					const _self = this;

					var data = new FormData();
					data.append('oab', this.numero_oab);
					data.append('senha', this.senha_advogado);

					Http.open("POST", url);
					Http.send(data);
					Http.onreadystatechange = (e) => {
						if (Http.readyState == 4 && Http.status == 200) {
							var res = Http.responseText;
							res = JSON.parse(res);
							if (res.loginEfetuado) {
								sessionStorage.setItem("token", res.token);
								sessionStorage.setItem("num_oab", _self.numero_oab);
								_self.$router.push('/escolhe-opcao');
								_self.$emit('alert_called', { message: 'Login efetuado com sucesso!', type: 'success' });
							} else {
								this.$emit('loader', { active:false });
								_self.$emit('alert_called', { message: 'A senha está incorreta!', type: 'danger' });
							}
						}
					}
				}
				return;
			},
			verificaTokenValido(token) {
				return new Promise((resolve, reject) => {
					const Http = new XMLHttpRequest();
					const url = this.valida_token_url;

					let data = new FormData();
					data.append('token', token);

					Http.open("POST", url);
					Http.send(data);
					Http.onreadystatechange = (e) => {
						if (Http.readyState == 4 && Http.status == 200) {
							var res = Http.responseText;
							res = JSON.parse(res);
							if(res.mode) {
								if(res.mode === "sandbox") {
									this.aviso_teste = true;
								}
							}
							if (res.tokenValido) {
								resolve(true);
							} else {
								resolve(false);
							}
						}
					}
				});
			}
		},
		beforeMount() {
			this.$emit('loader', { active:true });

			const Http = new XMLHttpRequest();
			const url = this.consulta_inscricoes_abertas;
			const _self = this;

			Http.open("GET", url);
			Http.send();
			Http.onreadystatechange = (e) => {
				if (Http.readyState == 4 && Http.status == 200) {
					var res = Http.responseText;
					res = JSON.parse(res);
					if (res.status_inscricoes && res.mode) {
						if(res.status_inscricoes === "Em curso") {
							_self.em_curso = true;
							_self.encerradas = false;
							_self.fechadas = false;
						}
						if(res.status_inscricoes === "Encerradas") {
							_self.em_curso = false;
							_self.encerradas = true;
							_self.fechadas = false;
						}
						if(res.status_inscricoes === "Fechadas") {
							_self.em_curso = false;
							_self.encerradas = false;
							_self.fechadas = true;
						}
						if(res.mode === "sandbox") {
							_self.aviso_teste = true;
						}
					}
					this.$emit('loader', { active:false });
				}
			}

			if (sessionStorage.token) {
				this.verificaTokenValido(sessionStorage.getItem('token')).then((verificaToken) => {
					//console.log(verificaToken);
					if (verificaToken) {
						this.$store.commit('get_storage');
						this.$emit('alert_called', { message: 'Você já está logado no sistema', type: 'success' });
						this.$router.push('/escolhe-opcao');
					} else {
						sessionStorage.removeItem('token');
						sessionStorage.removeItem('varas');
						sessionStorage.removeItem('tipos_escolhidos');
					}
				});

			} else {
				sessionStorage.removeItem('token');
				sessionStorage.removeItem('varas');
				sessionStorage.removeItem('tipos_escolhidos');
			}
		}
	};
</script>
<style lang="scss">
	.login {
		background-image: url('https://advogadosoab.arcoinformatica.com.br/view/public/img/bkg-vitoria.jpg');
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #ddd;
		display: flex;
		align-items: center;
		justify-content: center;
		background-size: cover;
		overflow-y: auto;


		@media (max-width: 480px) {
			.box-login {
				width: 90%;
			}
		}

		@media (max-width: 768px) {
			.box-login {
				width: 60%;
			}
		}

		@media (min-width: 768px) {
			.box-login {
				width: 440px;
			}
		}

		.box-login {
			background-color: white;
			padding: 50px;
			box-shadow: 0 0 30px #717171;

			&::before {
				content: '';
				position: fixed;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(0, 0, 0, 0.4);
				z-index: -1;
				pointer-events: none;
				margin: 0;
			}

			.logo {
				background-image: url('https://www.oabes.org.br/img/logo/logo.png');
				width: 100%;
				padding-bottom: 15%;
				background-size: contain;
				background-position: left;
				background-repeat: no-repeat;
			}

			.info {
				font-size: 14px;
				text-align: left;
				width: 100%;
				padding-top: 30px;
				display: none;

				.info-senha {
					span {
						color: #737373;
						line-height: 20px;
					}
				}

				&.active {
					display: block;
				}
			}

			.field {
				margin-top: 20px;

				input {
					font-size: 16px;
					width: 100%;
					padding-top: 15px;
					padding-bottom: 5px;
					border: 0;
					outline: 0;
					border-bottom: solid 1px #357a9b;
					color: #333;
				}
			}

			.button {
				margin-top: 30px;
				width: 100%;
				display: flex;
				justify-content: flex-end;

				button {
					padding: 10px 30px;
					border: 0;
					background-color: #357a9b;
					color: white;
					font-size: 14px;
					cursor: pointer;

					&:hover {
						background-color: #0a435f;
						transition: .3s all ease-in-out;
					}
				}
			}
		}
	}

	.senha {

		-webkit-text-security: disc;
		-moz-text-security: disc;
		-o-text-security: disc;
	}
	ul.digits-list {
		width: 100%;
		min-width: 100%;
	}
	input[type=number]::-webkit-inner-spin-button,
	input[type=number]::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	.digit {
		background-color: lightgray;
		min-width: 12px;
    	width: 16px;
		line-height: 16px;
		height: 16px;
		padding: 3px;
		color: #454545;
		border-radius: 2.5px;
	}
	.digit-active {
		background-color: #c63c33;
		color: #fff;

	}
	.digit-blue {
		background-color: rgb(46 127 169);
		color: #fff;
	}
	.digit-blue-trevoso {
		background-color: #042954;
		color: #fff;
	}
	@media screen and (max-width: 600px) {
		ul.digits-list {
			width: 100%;
			min-width: 100%;
		}
	}
	div.digits-list {
		flex-wrap: wrap;
	}
	.message_alert{
        background-color:#ffc3c3; 
        margin: 15px auto; 
        padding: 8px;
        border:1px solid red;
        border-radius: 4px;
        color:red;
    }

</style>